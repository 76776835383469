@media (min-width: 700px) {
    :root {
        --size: 32px;
        --quarter_size: 8px;
        --sixth_size: 4px;
        --perspective: 150px;
        --padding: 20px;
        --symbol-font-size: 22px;
        --size-table: 20;
        --back-font-size: 40px;
        --selector-size: 150px;
        --selector-multiplier: 6;
    }
}

@media (max-width: 699px) and (min-width: 405px) {
    :root {
        --size: 18px;
        --quarter_size: 4px;
        --sixth_size: 2px;
        --perspective: 150px;
        --padding: 2px;
        --symbol-font-size: 16px;
        --size-table: 21;
        --back-font-size: 38px;
        --selector-multiplier: 7;
    }
}

@media (max-width: 405px) {
    :root {
        --size: 12px;
        --quarter_size: 2px;
        --sixth_size: 1px;
        --perspective: 150px;
        --padding: 2px;
        --symbol-font-size: 20px;
        --size-table: 27;
        --back-font-size: 24px;
        --selector-multiplier: 7;
    }
}


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.symbol_wrapper {
    box-shadow: inset 2px 2px 10px 4px rgba(0, 0, 255, 0.5);
    border: 1px solid black;
    width: var(--size);
    height: var(--size);
    perspective: var(--perspective);
    display: inline-block;
    margin: 2px 3px;
}


.symbol_wrapper.no_symbol_wrapper {
    border: 1px solid transparent;
}

.symbol_face {
    position: relative;
    width: var(--size);
    height: var(--size);
    display: block;
    transition: transform 1.5s;
    transform-style: preserve-3d;
}

.symbol_side {
    position: absolute;
    width: var(--quarter_size);
    height: var(--size);

}

.symbol_front {
    transform: rotateY(0deg) translateZ(var(--sixth_size));
}

.symbol_back {
    transform: rotateY(180deg) translateZ(var(--sixth_size));
}

.symbol_left {
    background: linear-gradient(#262626, #eaeaea, #262626);
    transform: rotateY(-90deg) translateZ(calc(0px - var(--size) + var(--sixth_size)));

}

.symbol_right {
    background-color: red;
    transform: rotateY(90deg) translateZ(calc(0px - var(--sixth_size)));

}

.symbol {
    transform: rotateY(-90deg);
}

#right {
    transform: rotateY(90deg);
}

.symbol_icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.symbol_face.rotated {
    transform: rotateY(-180deg);
}

.symbol_face.half-rotated {
    transform: rotateY(-90deg);
}

.row {
    display: flex;
}

.snd_line_op {
    text-align: right;
    padding-right: calc(var(--size) / 3);
}

.operator {
    margin: 2px;
    cursor: pointer;
}

#equation {
    padding: 1%;
    position: relative;
    text-align: center;
    width: calc(var(--size-table) * var(--size));
    max-width: 600px;
    margin: calc(2 * var(--size)) auto;
    border: 3px solid black;
    background-color: #EFEFEF;

}

#equation.solved {
    pointer-events: none !important;
}

#equation table {
    margin: auto;
    padding: var(--padding);
}

.operator, .equal {
    font-weight: bold;
    font-size: var(--symbol-font-size);
}

.no_symbol {
    width: var(--size);
    height: var(--size);
    background-color: #EDEDED;
    display: block;
}

.clickable .no_symbol, .clickable .symbol_wrapper {
    cursor: pointer;
}

#solve_button {
    max-width: 300px;
    width: 100%;
    padding: 10px;
    font-size: 17px;
    text-transform: uppercase;
    border: none;
    border-radius: 10px;
    background-color: #8dd251;
    cursor: pointer;
    color: #444444;
    letter-spacing: 1px;
    font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
    font-weight: bold;
}

.noselect, td, * {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

audio {
    display: none;
}

#solve_button.true_rotating.true_solved > div {
    width: 40px;
    margin: 0 auto;
    white-space: nowrap;
    overflow: hidden;
}

#solve_button.true_rotating.true_solved > div > div {
    display: table-row;
    white-space: nowrap;
    padding-left: 100%;
    animation: marquee 2s linear infinite; /* Time must be adjusted based on total width of scrolled elements*/
}

#solve_button.true_rotating.true_solved > div > div p {
    width: 40px; /* Width of p elements must match the width of marquee "window"*/
    display: table-cell;
    padding: 14px 0 20px 40px;
}

/* Make it move */
@keyframes marquee {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

header {
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Comic Sans MS', 'Chalkboard SE', 'Comic Neue', sans-serif;
    letter-spacing: 7px;
    margin-top: 30px;
    padding-bottom: 20px;
    text-decoration: underline;
}

#contattami {
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: #f1f1f1;
    letter-spacing: 1px;
    font-size: 12px;
    padding: 1px 5px;
}

#contattami a {
    text-decoration: none;
}

.selecting .selection_container {
    display: flex !important;
    flex-flow: row wrap;
    padding: 2px;
}

.selecting table {
    opacity: 0.05;
    pointer-events: none;
}

.selection_container {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    --d: 24px;
    position: absolute;
    width: calc(var(--selector-multiplier) * var(--symbol-font-size));
    height: calc(var(--selector-multiplier) * var(--symbol-font-size));
    background-color: #00000022;
}

.selection_container button {
    padding: 0;
    border-radius: 0;
    cursor: pointer;
    background: none;
    width: var(--symbol-font-size);
    height: var(--symbol-font-size);
    margin: 2px;
    border: 1px solid black;
}

.selection_container button.blank_symbol_selector {
    border: none;
}

img {
    max-width: 100%
}

.symbol_wrapper_wrapper {
    position: relative;
}

.clicked_3 .blank_symbol_selector {
    visibility: hidden;
    pointer-events: none;
}

.blank_symbol_selector {
    text-decoration: none;
    position: relative;
    border: 1px solid black;
}

.blank_symbol_selector:after {
    content: "✗";
    color: #c41c1c;
    text-decoration: none !important;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    line-height: 5px;
    font-size: 20px;
    font-weight: bold;
}

.symbol_back {
    line-height: var(--size);
    font-weight: bold;
    background-color: white;
    font-family: 'Indie Flower', cursive;
    font-size: var(--back-font-size);
}

.symbol_back {
    font-size: var(--back-font-size);
}

tr:nth-of-type(3) td {
    border-bottom: 3px solid black;
    padding-bottom: 10px;
}

tr:nth-of-type(4) td {
    padding-top: 10px;
}

table {
    border-collapse: collapse;
}

.App.loading:after {
    content: " ";
    width: 100%;
    height: 100%;
    background-color: black;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    animation: beat .55s infinite alternate;
}

@keyframes beat {
    to {
        opacity: 90%;
    }
}